// GENERATED FILE - DO NOT MODIFY
import { EmployeeModelBase } from "api/models/company/employee/employee-model-base";
import { TimeEntryEmployeeModel } from "api/models/company/employee/time-entry-employee-model";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { ProjectGroupModel } from "api/models/company/project/project-group-model";
import { TimesheetEntryModel } from "api/models/company/timesheet/timesheet-entry-model";
import { TimesheetEntrySaveModel } from "api/models/company/timesheet/timesheet-entry-save-model";
import { TimesheetModel } from "api/models/company/timesheet/timesheet-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class TimesheetProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetTimesheet(timesheetId: number, requestConfig?: HttpClientRequestConfig): Promise<TimesheetModel | null> {
        const uri = this.buildUri(
            "/timesheets/{timesheetId}",
            { timesheetId: (timesheetId === null || timesheetId === undefined) ? undefined : timesheetId.toString() },
            null
        );

        const data: TimesheetModel | null = await this.get<TimesheetModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Timesheet.TimesheetModel");
        return data;
    }

    public async GetTimesheetEntry(timesheetId: number, timesheetEntryId: number, requestConfig?: HttpClientRequestConfig): Promise<TimesheetEntryModel | null> {
        const uri = this.buildUri(
            "/timesheets/{timesheetId}/entries/{timesheetEntryId}",
            { timesheetId: (timesheetId === null || timesheetId === undefined) ? undefined : timesheetId.toString(), timesheetEntryId: (timesheetEntryId === null || timesheetEntryId === undefined) ? undefined : timesheetEntryId.toString() },
            null
        );

        const data: TimesheetEntryModel | null = await this.get<TimesheetEntryModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Timesheet.TimesheetEntryModel");
        return data;
    }

    public async DeleteTimesheetEntry(timesheetId: number, timesheetEntryId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/timesheets/{timesheetId}/entries/{timesheetEntryId}",
            { timesheetId: (timesheetId === null || timesheetId === undefined) ? undefined : timesheetId.toString(), timesheetEntryId: (timesheetEntryId === null || timesheetEntryId === undefined) ? undefined : timesheetEntryId.toString() },
            null
        );

        await this.delete<void>(uri, requestConfig);
    }

    public async SaveTimesheetEntries(timesheetEntries: Array<TimesheetEntrySaveModel | null> | null, previousPayPeriod: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/timesheets/entries",
            null,
            { previousPayPeriod: (previousPayPeriod === null || previousPayPeriod === undefined) ? undefined : previousPayPeriod.toString() }
        );

        await this.post<Array<TimesheetEntrySaveModel | null> | null, void>(uri, timesheetEntries, requestConfig);
    }

    public async CompleteTimesheet(timesheetId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/timesheets/{timesheetId}/complete",
            { timesheetId: (timesheetId === null || timesheetId === undefined) ? undefined : timesheetId.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async ApproveTimesheets(timesheetIds: number[] | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/timesheets/approve",
            null,
            null
        );

        await this.post<number[] | null, void>(uri, timesheetIds, requestConfig);
    }

    public async GetProjectsForTimesheets(prefix: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectBaseModel[] | null> {
        const uri = this.buildUri(
            "/timesheets/projects",
            null,
            { prefix: (prefix === null || prefix === undefined) ? undefined : prefix, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectBaseModel[] | null = await this.get<ProjectBaseModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectBaseModel");
        return data;
    }

    public async GetAllowedExpenseCompanySelection(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/timesheets/allowed-expense-company-selection",
            null,
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async GetGroups(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {
        const uri = this.buildUri(
            "/timesheets/groups",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectGroupModel[] | null = await this.get<ProjectGroupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectGroupModel");
        return data;
    }

    public async GetEquipmentGroups(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {
        const uri = this.buildUri(
            "/timesheets/equipmentgroups",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectGroupModel[] | null = await this.get<ProjectGroupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectGroupModel");
        return data;
    }

    public async GetEmployees(payPeriodDate: Date, onlyCompleted: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TimeEntryEmployeeModel[] | null> {
        const uri = this.buildUri(
            "/timesheets/employees",
            null,
            { payPeriodDate: (payPeriodDate === null || payPeriodDate === undefined) ? undefined : payPeriodDate.toString(), onlyCompleted: (onlyCompleted === null || onlyCompleted === undefined) ? undefined : onlyCompleted.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: TimeEntryEmployeeModel[] | null = await this.get<TimeEntryEmployeeModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Employee.TimeEntryEmployeeModel");
        return data;
    }

    public async GetEquipments(employeeId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        const uri = this.buildUri(
            "/timesheets/equipments",
            null,
            { employeeId: (employeeId === null || employeeId === undefined) ? undefined : employeeId.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EquipmentModel[] | null = await this.get<EquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Equipment.EquipmentModel");
        return data;
    }

    public async CanAddOnGoingTimeSheet(forEmployees: Array<EmployeeModelBase | null> | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EmployeeModelBase[] | null> {
        const uri = this.buildUri(
            "/timesheets/entries/can-add-ongoing-timesheet",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EmployeeModelBase[] | null = await this.post<Array<EmployeeModelBase | null> | null, EmployeeModelBase[] | null>(uri, forEmployees, requestConfig);
        this.transformModels(data, "Company.Employee.EmployeeModelBase");
        return data;
    }
}
